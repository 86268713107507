import React from "react"
import Styles from "./footer.module.css"

const Footer = ({ image }) => (
  <div className="h-half relative">
    <div
      className="absolute h-full top-0 left-0 z-10 w-full"
      style={{
        backgroundImage: `url(${image})`,
        backgroundSize: `cover`,
        backgroundPosition: `center`,
        filter: `grayscale(100%)`,
      }}
    ></div>
    <div
      className={`h-full flex justify-center flex-col relative z-20 items-center ${Styles.mixblend}`}
      style={{ background: `rgba(22,22,37,0.75)` }}
    >
      <span className="text-xl md:text-3xl font-bold text-white">
        <a href="mailto:hello@redgarden.co.nz">hello@redgarden.co.nz</a>
      </span>
    </div>
  </div>
)

export default Footer
