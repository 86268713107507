import React from "react"
import { useSpring, animated } from "react-spring"
import Styles from "../components/standardpage.module.css"

const Content = props => {
  const anims = useSpring({
    transform: `translate3d(${props.right ? "-8em" : "8em"}, 4em, 0)`,
    from: {
      transform: `translate3d(${props.right ? "-8em" : "8em"}, 20em, 0)`,
    },
    delay: 200,
  })

  return (
    <animated.main
      className={`mt-20 rounded-sm lg:mt-32 p-10 md:p-16 mx-auto max-w-5xl bg-white lg:shadow-2xl ${Styles.offset}`}
      style={anims}
    >
      {props.children}
    </animated.main>
  )
}

const ContentLayout = props => {
  const anims = useSpring({
    width: `100%`,
    from: { width: `0%` },
    delay: 600,
  })

  return (
    <div className="flex bg-offwhite">
      <div className={`flex-1 z-30 ${props.right ? "order-2" : ""}`}>
        <Content right={props.right}>{props.children}</Content>
      </div>

      <div className="flex-1 hidden lg:block relative">
        <div
          className="h-full w-full absolute top-0 left-0"
          style={{
            backgroundImage: `url(${props.featureImage})`,
            backgroundSize: `cover`,
            backgroundPosition: `center`,
            filter: `grayscale(100%)`,
          }}
        ></div>
        <animated.div
          className={`h-full absolute z-10 bottom-0 left-0 bg-brand ${Styles.colorOverlay}`}
          style={anims}
        ></animated.div>
      </div>
    </div>
  )
}

export default ContentLayout
