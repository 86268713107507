import PropTypes from "prop-types"
import React, { useState } from "react"
import { useTrail, animated, config } from "react-spring"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import Logo from "./logo"

import "./global.css"
import headerStyles from "./header.module.css"

const Burger = props => {
  let { active } = props
  return (
    <div
      onClick={() => props.toggleMenu()}
      className={`${headerStyles.menuContainer} ${active ? "active" : ""}`}
    >
      <span className={`${active ? headerStyles.active : ""}`}></span>
      <div
        className={`${headerStyles.accent} ${
          active ? headerStyles.accentActive : ""
        }`}
      ></div>
    </div>
  )
}

const Menu = ({ active, location, ...props }) => {
  const navItems = [
    {
      slug: "about-us",
      label: "About us",
      direction: "up",
      active: location.pathname.includes("about-us"),
    },
    // {
    //   slug: "our-work",
    //   label: "Our work",
    //   direction: "right",
    //   active: location.pathname.includes("our-work"),
    // },
    {
      slug: "our-services",
      label: "What we do",
      direction: "down",
      active: location.pathname.includes("our-services"),
    },
    {
      slug: "contact-us",
      label: "Contact us",
      direction: "left",
      active: location.pathname.includes("contact-us"),
    },
  ]

  const trail = useTrail(navItems.length, {
    opacity: active ? 1 : 0,
    y: active ? 0 : 150,
    from: {
      opacity: 0,
      y: 150,
    },
    config: config.stiff,
  })

  const switchMenu = active => {
    if (active) {
      props.toggleMenu()
    }
  }

  return (
    <>
      <div
        className={`fixed ${
          active ? "flex" : "hidden"
        } justify-center flex-col lg:flex-row mt-20 items-center top-0 left-0 z-50 w-full ${
          headerStyles.menuOverlay
        }`}
      >
        <div className="flex-1 flex justify-center items-center">
          <ul className="flex flex-col justify-center md:justify-between md:justfy-center md:h-auto items-center lg:block">
            {trail.map(({ opacity, y, ...rest }, index) => (
              <div
                key={navItems[index]["slug"]}
                className={headerStyles.hoverItem}
              >
                <animated.li
                  style={{
                    ...rest,
                    opacity,
                    transform: y.interpolate(y => `translate3d(0,${y}px,0)`),
                  }}
                  className="text-2xl md:text-3xl lg:text-5xl font-bold uppercase text-center py-4 lg:text-left lg:py-6 text-brand"
                >
                  <AniLink
                    style={{
                      opacity:
                        navItems[index]["active"] || location.pathname === "/"
                          ? 1
                          : 0.7,
                    }}
                    cover
                    onClick={() => switchMenu(navItems[index]["active"])}
                    direction={navItems[index]["direction"]}
                    bg="#ef3f4a"
                    to={navItems[index]["slug"]}
                  >
                    {navItems[index]["label"]}
                  </AniLink>
                </animated.li>
              </div>
            ))}
          </ul>
        </div>

        <div className="flex-1 hidden lg:flex flex text-center lg:text-left justify-center items-center text-white">
          <div>
            <p className="text-white">We're always online.</p>
            <h3 className="uppercase font-bold text-xl text-brand mt-4 mb-2">
              Say hello
            </h3>
            <a className="hover:text-brand" href="mailto:hello@redgarden.co.nz">
              hello@redgarden.co.nz
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

const Header = ({ siteTitle, header, top, location }) => {
  const [menuState, setMenuState] = useState(false)

  const toggleMenu = active => {
    setMenuState(!menuState)
  }

  return (
    <>
      <header
        className={`${headerStyles.header} ${header ? "bg-dark" : ""} p-6 md:${
          top > 0 ? "p-6" : "p-8"
        } lg:${top > 0 ? "p-8" : "p-12"} fixed top-0 w-full z-40`}
      >
        <div className="flex justify-between">
          <span className="inline-block" style={{ width: `40px` }}></span>

          <AniLink paintDrip hex="#ef3f4a" to="/">
            <div className={headerStyles.logo}>
              <Logo className={headerStyles.logo} color="red" />
            </div>
          </AniLink>

          <Burger active={menuState} toggleMenu={() => toggleMenu()} />
        </div>
      </header>
      <Menu
        active={menuState}
        location={location}
        toggleMenu={() => toggleMenu()}
      />
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
