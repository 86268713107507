import React from 'react'

import './global.css'
import logoStyles from './logo.module.css'

const Logo = props => {
    return (
        <svg className={`${logoStyles.red}`} xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 100 17.9">
        <defs/>
            <path d="M16.4 4.8V3.3H14V13h2.5V8.2c0-2 .9-2.5 2.4-2.5h.6V3.1a3.4 3.4 0 00-3 1.7zM24.5 3.1a4.7 4.7 0 00-5 5 4.7 4.7 0 005 5A4.5 4.5 0 0029 10h-2.6a2 2 0 01-2 1.2 2.3 2.3 0 01-2.3-2.3h7.1a6.2 6.2 0 000-1 4.6 4.6 0 00-4.7-4.8zM22 7.2a2.3 2.3 0 012.3-2 2.2 2.2 0 012.3 2zM37.8 4.6a4 4 0 00-3.2-1.5c-2.5 0-4.5 2-4.5 5s2 5.1 4.5 5.1a3.7 3.7 0 003.2-1.6V13h2.5V0h-2.5zM35.2 11a2.7 2.7 0 01-2.6-3 2.6 2.6 0 012.6-2.7A2.6 2.6 0 0137.8 8a2.6 2.6 0 01-2.6 2.9zM49 4.7A3.8 3.8 0 0045.8 3c-2.5 0-4.5 2-4.5 5s2 5.1 4.5 5.1a3.8 3.8 0 003.2-1.6V13a2.3 2.3 0 01-2.4 2.6 2.3 2.3 0 01-2.4-1.5h-2.4c.2 2.3 2.1 3.6 4.9 3.6a4.5 4.5 0 004.8-4.7V3.3H49zM46.4 11A2.7 2.7 0 0144 8a2.6 2.6 0 012.5-2.7A2.6 2.6 0 0149 8.2a2.6 2.6 0 01-2.6 2.8zM60 4.7A3.9 3.9 0 0056.8 3c-2.5 0-4.5 2-4.5 5s2 5.1 4.5 5.1a3.8 3.8 0 003.2-1.6V13h2.5V3.3H60zM57.4 11A2.7 2.7 0 0155 8a2.6 2.6 0 012.5-2.7A2.6 2.6 0 0160 8.2a2.6 2.6 0 01-2.6 2.8zM66 4.8V3.3h-2.5V13H66V8.2c0-2 .9-2.5 2.4-2.5h.6V3.1a3.4 3.4 0 00-3 1.7zM76.8 4.6a4 4 0 00-3.1-1.5c-2.5 0-4.5 2-4.5 5s2 5.1 4.4 5.1a3.7 3.7 0 003.2-1.6V13h2.5V0h-2.5zM74.3 11a2.7 2.7 0 01-2.6-3 2.6 2.6 0 012.6-2.7A2.6 2.6 0 0176.9 8a2.6 2.6 0 01-2.6 2.9zM85.2 3.1a4.7 4.7 0 00-5 5 4.7 4.7 0 005 5 4.5 4.5 0 004.5-3.1H87a2 2 0 01-2 1.2 2.3 2.3 0 01-2.3-2.3H90A6.2 6.2 0 0090 8a4.6 4.6 0 00-4.8-4.9zm-2.4 4.1a2.3 2.3 0 012.3-2 2.2 2.2 0 012.3 2zM96.1 3.1a3.7 3.7 0 00-3 1.4V3.3h-2.4V13h2.5V7.7a2.1 2.1 0 012.2-2.5c1.3 0 2.1.9 2.1 2.5V13h2.5V7.3c0-2.7-1.6-4.2-3.9-4.2zM5 2.8a5.4 5.4 0 00-4 1.7A5.2 5.2 0 00.1 9a5.4 5.4 0 002.6 3.6 5.3 5.3 0 004.5-.1 5.6 5.6 0 003-3.5 5 5 0 00-1-4.5A5.6 5.6 0 005 2.8zM4.5 5C4.2 5 4 6.3 4 6.3s-.2-1-.5-1l-1.2.4 1.1-1c.1-.2-.1-.7-.1-.7l.6.5c.4-.2.7-1 .7-1.1a7.8 7.8 0 000 1.3 4.3 4.3 0 00.9.2z" className="cls-1"/>
        </svg>
    )
}

export default Logo